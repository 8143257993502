<template>
  <b-form-group>
    <b-input-group>
      <flat-pickr
        :value="dateRange"
        placeholder="ex: 2020-10-1 to 2020-10-15"
        class="form-control"
        :config="{mode:'range', dateFormat: 'Y-m-d', altFormat: 'j F, Y', altInput:true}"
        @on-close="setDateRange"
      />
      <b-input-group-append>
        <b-button
          variant="danger"
          size="sm"
          @click="resetDateRange"
        >
          Clear
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'DateRangeFilter',
  components: {
    flatPickr,
  },
  props: {
    dateRange: { type: Array, default: null },
  },
  methods: {
    setDateRange(selectedDates) {
      const startDate = this.$moment(selectedDates[0]).format('YYYY-MM-DD')
      const endDate = this.$moment(selectedDates[1]).format('YYYY-MM-DD')
      this.$emit('update:date-range', [startDate, endDate])
    },
    resetDateRange() {
      this.$emit('update:date-range', [null, null])
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
