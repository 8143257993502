<template>
  <div>
    <b-row>
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col md="6">
              <date-range-filter
                :date-range.sync="dateRange"
              />
            </b-col>
            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
            <b-col md="2">
              <b-button
                variant="success"
                class="btn btn-block mt-1 mt-md-0"
                @click="exportTransactions"
              >
                Export
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-table
            ref="table"
            :items="getTransactions"
            responsive
            :fields="tableColumns"
            :per-page="pagination.perPage"
            :current-page="pagination.currentPage"
            primary-key="id"
            empty-text="No Transactions"
            show-empty
            class="position-relative"
            @sort-changed="onSortChanged"
          >
            <!-- Column: Client -->
            <template #cell(client)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :text="avatarText(data.item.user.name )"
                  />
                </template>
                <span
                  v-if="Object.keys(data.item.user.profile).length !== 0"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.user.profile.first_name + ' '+ data.item.user.profile.last_name }}
                </span>
              </b-media>
            </template>
            <template #cell(monthly)="data">
              <b-avatar
                :id="`invoice-row-${data.item.id}`"
                size="32"
                :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.monthly).variant}`"
              >
                <feather-icon
                  :icon="resolveInvoiceStatusVariantAndIcon(data.item.monthly).icon"
                />
              </b-avatar>
            </template>
            <template #cell(created_at)="{item}">
              <div class="text-nowrap">
                {{ item.created_at }}
              </div>
            </template>
          </b-table>
        </b-card>
        <pagination
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          :current-page.sync="pagination.currentPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import { avatarText } from '@core/utils/filter'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'

export default {
  components: {
    DateRangeFilter,
    SearchButton,
    Pagination,
  },
  data() {
    return {
      dateRange: [],
      entityID: this.$store.getters['mainEntity/getEntityId'],
      loading: true,
      tableColumns: [
        { key: 'transaction_number', label: 'Transaction ID', sortable: true },
        { key: 'client', label: 'Donor Name', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'total_amount', sortable: true, formatter: val => `$${val.toFixed(2)}` },
        { key: 'net_amount', sortable: true, formatter: val => `$${val.toFixed(2)}` },
        { key: 'monthly', sortable: true },
        { key: 'created_at', label: 'Date', sortable: true },
      ],
      pagination: {
        totalRows: 5,
        currentPage: 1,
        perPage: 10,
      },
      sortingQuery: '',
    }
  },
  methods: {
    avatarText,
    onSortChanged(ctx) {
      const lookup = {
        transaction_number: 'orderByTransaction',
        type: 'orderByType',
        total_amount: 'orderByTotalAmount',
        client: 'orderByUser',
        monthly: 'orderByMonthly',
        created_at: 'orderByDate',
      }

      this.sortingQuery = `${lookup[ctx.sortBy]}=${ctx.sortDesc ? 'desc' : 'asc'}`

      this.refreshTable()
    },
    getTransactions() {
      const promise = this.$payment.get(`internalops/get-transaction?${this.sortingQuery}`, {
        params: {
          entity_id: this.entityID,
          page: this.pagination.currentPage,
          from: this.dateRange[0],
          to: this.dateRange[1],
        },
      })

      return promise.then(res => {
        const invoices = res.data.data[0].result
        this.pagination.totalRows = res.data.data[0].total
        this.pagination.perPage = res.data.data[0].per_page
        return invoices || []
      }).catch(() => [])
        .finally(() => {
          this.loading = false
        })
    },
    exportTransactions() {
      this.$payment.get('/internalops/get-transaction', {
        responseType: 'blob',
        params: {
          entity_id: this.$store.getters['mainEntity/getEntityId'],
          export: true,
        },
      }).then(res => {
        const blob = new Blob([res.data])
        saveAs(blob, 'invoice.xlsx')
      })
    },
    resolveInvoiceStatusVariantAndIcon(status) {
      if (status) return { variant: 'success', icon: 'CheckCircleIcon' }
      return { variant: 'error', icon: 'InfoIcon' }
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
